@import "../../../../../../sg_news/Resources/Public/Sass/styles";

.intro-section {
	.tx-sgnews-teaser-title {
		margin: 50px 0;
		text-align: center;
	}

	.tx-sgnews-teaser-meta {
		margin-bottom: 60px;
		text-align: center;
		font-size: 16px;

		span {
			margin: 0 12px;
			&:before {
				margin-right: 5px;
				font-family: 'Glyphicons Halflings';
				display: inline-block;
				font-size: 15px;
				vertical-align: middle;
			}

			a {
				text-decoration: none;
			}
		}

		.author:before {
			content: '\e008';
		}

		.date:before {
			content: '\e109';
		}

		.category:before {
			content: '\e042';
		}
	}
}

.tx-sgnews .carousel {
	display: none;

	@media (min-width: $screen-md-min) {
		display: block;
	}

	a {
		text-decoration: none;
	}
}
