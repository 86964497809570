.navbar-default {
	display: none;

	.dropdown > a > .flag-icon {
		display: none;
	}

	@media (min-width: $grid-float-breakpoint) {
		display: block;
	}

	.hide-in-desktop-menu {
		display: none;
	}

	.glyphicon,
	.flag-icon {
		margin-right: 4px;
	}
}

.default-navbar-sticky {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, .5);

	.navbar-nav {
		position: relative;
	}
}

.navbar-nav.navbar-left > li:first-child a {
	padding-left: 0;
}

.navbar-separator-right {
	&:after {
		content: '';
		display: inline-block;
		position: absolute;
		top: 30%;
		right: 0;
		bottom: 25%;
		width: 1px;
		background-color: rgba(255, 255, 255, .3);
	}
}

.navbar-separator-left {
	position: relative;

	&:before {
		content: '';
		display: inline-block;
		position: absolute;
		top: 30%;
		left: 0;
		bottom: 25%;
		width: 1px;
		background-color: rgba(255, 255, 255, .3);
	}
}
