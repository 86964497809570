.hasJs .default-accordion-content {
	display: none;
	padding: 15px 0;

	.noJs & {
		display: block;
	}

	@media print {
		display: block !important;
		padding: 0;
		border: none;
		opacity: 1 !important;
		height: auto !important;
		max-height: inherit !important;
	}
}

.default-accordion-wrap {
	margin-bottom: 5px;
}

.default-accordion-contentSub h4 {
	display:none;
}


.default-accordion-toggle {
	padding: $panel-heading-padding;
	background-color: $panel-default-heading-bg;
	border-radius: $panel-border-radius;
	border: 1px solid $panel-default-border;

	a {
		text-decoration: none;
		color: $panel-default-text;
		font-weight: bold;
		font-size: 14px;

		&:before {
			margin-right: 15px;
			content: '\e259';
			display: inline-block;
			font-family: 'Glyphicons Halflings';
			font-size: .7em;
			background-color: $panel-default-text;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			color: $panel-default-heading-bg;
			text-align: center;
			line-height: 2.2;
		}
	}

	@media print {
		display: none;
	}
}

.default-accordion-open .default-accordion-content {
	display: block;
}

.default-accordion-toggle h1,
.default-accordion-toggle h2,
.default-accordion-toggle h3,
.default-accordion-toggle h4,
.default-accordion-toggle p {
	margin: 0;
	padding: 0;
	background: none;
	line-height: 1.375;
	cursor: pointer;
}

.default-accordion-open .default-accordion-toggle a:before {
	content: '\e260';
}

.default-accordion-wrap[data-default-accordion-animation="slideUpwards"] {
	display: flex;
	flex-direction: column;

	.csc-header {
		order: 2;
	}

	.default-accordion-content {
		order: 1;
	}
}

.default-accordion-content.default-accordion-transition-fallback {
	display: none;
}

.default-accordion-content.default-accordion-transition-fade {
	transition: opacity .8s ease, max-height 0s ease .5s;
	opacity: 0;
	max-height: 0;
	overflow: hidden;
	display: block;
}

.default-accordion-open .default-accordion-content.default-accordion-transition-fade {
	opacity: 1;
	max-height: 999999999px;
	transition: opacity .8s ease;
}
