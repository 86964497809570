.noJs .smart-label {
	margin-bottom: 3px;
}

.hasJs .smart-label {
	margin: -12px 0 0 11px;
	padding: 3px;
	visibility: hidden;
	opacity: 0;
	position: absolute;
	transform: translateY(10px);
	transition: all .4s ease;
	font-size: 12px;
	font-weight: normal;
	background-color: transparent;

	&.filled {
		visibility: visible;
		opacity: 1;
		transform: translateY(0);
		background-color: #FFF;

		+ input,
		+ select,
		+ textarea {
			padding-top: 12px;
		}
	}
}
