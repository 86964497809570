.cookie-alert {
	padding: 15px 0 0;
	position: fixed;
	bottom: 0;
	left: 0;
	background-color: $brand-secondary;
	z-index: 100;
	color: #FFF;
	width: 100%;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, .5);
	transition: transform $base-transition-timing;

	.col-sm-11 a {
		color: #FFF;
		font-weight: bold;
	}

	.btn {
		margin-top: 5px;
		margin-bottom: 5px !important;
	}

	&.closed {
		transform: translateY(110%);
	}
}
