@mixin preloader() {
	position: relative;

	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, .8);
	}

	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		animation: preloader-rotate 1s infinite;
	}
}

@mixin preloader-fixed {
	&::before,
	&::after {
		position: fixed;
	}
}

@mixin preloader-display-helper() {
	display: inline-block;
}

@keyframes preloader-rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

