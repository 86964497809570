$brand-secondary: $gray-darker;

.drop-up-component {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100000;
	visibility: hidden;
	transition: visibility 0s .4s;

	.drop-up-component-close {
		margin-left: 10px;
		color: #FFF;
		text-decoration: none;
	}
}

.drop-up-search {
	padding: 25px 40px;
	background-color: $brand-secondary;
	height: 90px;
	transform: translateY(-90px);

	.search-field {

		input {
			padding-top: 8px;
			height: 38px;
			border-radius: 0;
			width: calc(100% - 30px);
			display: inline-block;
		}

	}
}

.drop-up-login {
	padding: 25px 0;
	background-color: $brand-secondary;
	color: #FFF;
	height: 180px;
	text-align: right;
	transform: translateY(-180px);

	input,
	button {
		margin-bottom: 10px;
		border-radius: 0;
	}

	.drop-up-component-close {
		transform: translateY(-103px);
	}

	.form-group {
		display: inline-block;
		width: calc(100% - 40px);
	}

	input {
		width: 100%;
	}

	@media (min-width: 680px) {
		height: 90px;
		transform: translateY(-90px);

		input,
		button {
			margin-left: 8px;
			display: inline-block;
		}

		input {
			width: 250px;
		}

		.drop-up-component-close {
			transform: none;
		}

		.form-group {
			display: inline-block;
		}
	}
}

body {
	transition: transform .4s ease-out;

	&.drop-up-search-open,
	.noJs & {
		transform: translateY(90px);

		.drop-up-search {
			visibility: visible;
			transition: none;
		}
	}

	&.drop-up-login-open,
	.noJs & {
		transform: translateY(180px);

		@media (min-width: 680px) {
			transform: translateY(90px);
		}

		.drop-up-login {
			visibility: visible;
			transition: none;
		}
	}

	.noJs & {
		transform: translateY(410px);

		.drop-up-login {
			transform: translateY(-410px);
		}

		@media (min-width: $screen-md) {
			transform: translateY(260px);

			.drop-up-login {
				transform: translateY(-260px);
			}
		}
	}
}
