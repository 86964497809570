@import 'svg';
@import 'Theme/theme';
@import '../../../node_modules/flag-icon-css/sass/variables';

$flag-icon-css-path: '../../../node_modules/flag-icon-css/flags';
@import '../../../node_modules/flag-icon-css/sass/flag-icon-base';
@include flag-icon(de);
@include flag-icon(gb);

$margin-base-vertical: 15px;
$base-transition-timing: .4s ease;

$fa-font-path: '../Fonts/FontAwesome';
@import 'FontAwesome/font-awesome';
@import url('https://fonts.googleapis.com/css?family=Lato:300,400');
@import 'Components/ui';
@import 'Components/plugins';
@import 'Components/partials';

html {
	overflow-y: scroll;
}

html,
body {
	height: 100%;
	background-color: $bernd-silver;
	background-color: #BCBCBC;
	color: #333;
	font-family: 'Tahoma', 'Lato', 'Arial', 'Helvetica', sans-serif;
	-webkit-font-smoothing: antialiased;
}

.default-page-wrap {
	margin: auto;
	padding-top: 58px;
	background-color: #313234;
	width: 100%;
	max-width: 1970px;
	box-shadow: 0 0 40px rgba(0, 0, 0, .2);
	position: relative;

	@media (min-width: $grid-float-breakpoint) {
		padding-top: 110px;
	}
}

::selection {
	color: #FFF;
	background: #333;
}

p {
	margin-bottom: $margin-base-vertical;
}

video,
audio {
	max-width: 100%;
}

.h1, .h2, .h3, .h4, .h5, .h6,
h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	padding-top: $margin-base-vertical;
	margin-bottom: .5em;
}


.h1,h1 {color:$bernd-red;}
.light-bg .h1, .light-bg h1 {color:$bernd-gray;}

.h2,h2,
.h3,h3,
.h4,h4,
.h5,h5,
.h6,h6 {color:$bernd-gray;}
.dark-bg .h2, .dark-bg h2,
.dark-bg .h3, .dark-bg h3,
.dark-bg .h4, .dark-bg h4,
.dark-bg .h5, .dark-bg h5,
.dark-bg .h6, .dark-bg h6 {color:#FFF;}



.page-header {
	margin-top: 0;
	padding-top: $margin-base-vertical;
}

.btn {
	margin-bottom: $margin-base-vertical;
	vertical-align: top;

	&:last-child {
		margin-bottom: 0;
	}
}

.content,
.header {
	background-color: #FFF;
}

.content {
	padding: $margin-base-vertical*2 0;
}

.default-content-element {
	margin-bottom: $margin-base-vertical;

	&:last-child {
		margin-bottom: 0;
	}
}

main {
	min-height: 500px;
	background-color: #FFF;
}

.main-content {
	// nasty trick to prevent margin collapse with footer
	padding-top: 35px;
	padding-bottom: 1px;
	margin-bottom: -1px;

	> .default-content-element {
		@include container-fixed;

		&.row {
			padding-left: 0;
			padding-right: 0;
		}

		@media (min-width: $screen-sm-min) {
			width: $container-sm;
		}
		@media (min-width: $screen-md-min) {
			width: $container-md;
		}
		@media (min-width: $screen-lg-min) {
			width: $container-lg;
		}

		&:last-child {
			margin-bottom: $margin-base-vertical *4;
		}
	}
}




.dropdown-menu {
	background-color: $bernd-gray;
    top: 100%;
	li a {
		color:#FFF;
	}
}



ul {
	padding: 0;
	list-style-position: inside;
}
.main-content ul {
	list-style-position: outside;
	margin-left:15px;	
}
ul li ul {
	margin-left:20px;	
}
ul li ul li ul {
	margin-left:40px;	
}


.footer {
	padding: $margin-base-vertical*2 0;

	&.dark-bg {
		margin-top: 0;
		margin-bottom: 0;
	}
	
	&.silver-bg {
		margin-top: 0;
		margin-bottom: 0;
	}
	
	ul {
		list-style-type: none;
		list-style-position: inside;
	}
}

.well *:last-child {
	margin-bottom: 0; 
}

.progressbar {
	margin-bottom: $margin-base-vertical;
}

.dark-bg {
	// this is a known and wanted violation of the CGL..
	// we need the top-margin here as there is no other way to add a distance between the section and the
	// content-element before it
	margin-top: $margin-base-vertical*3;
	margin-bottom: $margin-base-vertical*3;
	background: $gray-dark;
	color: #FFF;

	// elements inside the intro-section are an exception
	.intro-section & {
		margin: 0;
	}
}

.light-bg {
	margin-top: $margin-base-vertical*3;
	margin-bottom: $margin-base-vertical*3;
	background: $gray-light;
	color:$bernd-gray;
}

.silver-bg {
	margin-top: $margin-base-vertical*3;
	margin-bottom: $margin-base-vertical*3;
	background:$bernd-silver;
	color:$bernd-gray;
}




.intro-section {
	margin-bottom: $margin-base-vertical * 2;
}

// form related styles
.parsley-errors-list {
	> li {
		padding: 5px 10px;
		color: #FFF;
		background-color: #AB0D10;
		list-style: none;
	}
}

.parsley-error {
	border-color: #AB0D10;
	border-radius: 0;
}

.btn-default {
	text-shadow: none;
}
