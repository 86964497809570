.tx-dftabs-tabMenu {
	margin-bottom: 15px;
}

.tx-dftabs-plugin1-hasJS .tx-dftabs-tabTitle,
.tx-dftabs-plugin1-hasJS .tx-dftabs-tabContent {
	display: none;
}

.tx-dftabs-plugin1-hasJS .tx-dftabs-tabMenu,
.tx-dftabs-plugin1-hasJS .tx-dftabs-tabContentSelected {
	display: block;
}

.tx-dftabs-plugin1-hasJS .tx-dftabs-tabContentSelected {
	overflow: hidden;
}

.nav-tabs > li.tx-dftabs-tabMenuEntrySelected > a,
.nav-tabs > li.tx-dftabs-tabMenuEntrySelected > a:focus,
.nav-tabs > li.tx-dftabs-tabMenuEntrySelected > a:hover {
	color: #555;
	cursor: default;
	background-color: #FFF;
	border: 1px solid #DDD;
	border-bottom-color: transparent;
}
