.header {
	position: absolute;
	top: 0;
	width: 100%;

	.navbar-inverse {
		/*margin-bottom: 0;*/
		border-radius: 0;
	}
}
