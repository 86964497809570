.header-meta-bar {
	padding: 5px 0;
	height: 63px;
	width: 100%;
	background-color: #FFF;
	z-index: 10;
}

.logo {
	max-width: 447px;
	display: inline-block;

	top: -1px;
	position: relative;
	a {
		img {width:350px;}
	}
}


.navbar-fixed-top {
	top: 70px;
}


@media (min-width: $screen-sm-min) {
	.logo-min {display:none;}
}
@media (max-width: $screen-sm-min) {
	.logo {display:none;}
}