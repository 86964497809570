//$mm_backgroundColor: $brand-secondary;
$mm_borderColor: rgba(255, 255, 255, .7);
$mm_textColor: #FFF;
$mm_dimmedTextColor: rgba(255, 255, 255, .7);
$mm_fontSize: $font-size-base;
$mm_listitemIndent: 20px;
$mm_listitemPadding: 13px;

// mmenu does not distinguish between the distance of the item and its border
// so we have to override this value here
.mm-listview > li:not(.mm-divider):after {
	left: 0 !important;
}

.mm-menu.mm-offcanvas {
	z-index: 1;
	box-shadow: 0 0 30px rgba(0, 0, 0, .4);
}

.mm-menu {

	.highlight-in-responsive-menu {
		background: rgba(0, 0, 0, .08);

		&.mm-selected > a {
			background: transparent !important;
		}
	}

	.active {
		background: $brand-primary;
		color: #FFF;

		&.mm-selected > a {
			background: $brand-primary !important;
		}

		.mm-next:after,
		.mm-next:before {
			border-color: #FFF !important;
		}
	}

	.glyphicon,
	.flag-icon {
		margin-right: 4px;
	}

	.caret {
		display: none;
	}

	.mm-panel:after {
		margin: 40px auto 0;
		content: '';
		display: block;
		width: 60px;
		height: 60px;
		background-image: url('/typo3conf/ext/project_theme/Resources/Public/Images/logo-small-inverted.svg');
		background-size: 100%;
	}
}

.navbar-toggle {
	margin: 0;
	position: absolute;
	right: 15px;
	top: 10px;
	border-color: #000;
	border-radius: 0;

	.icon-bar {
		background-color: #000;
	}
}

@import '../../../node_modules/jquery.mmenu/dist/css/jquery.mmenu.all.css';
